<template>
	<div class="partnerDetail">
		<div class="main-content" v-if="detailItem">
			<div>
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/home' }">
						<img src="../assets/img/icon_home.png" class="img-home">
						{{language==='0'?'首页':'Home Page'}}
					</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/about' }">
						<span class="pointer">
							{{language==='0'?'关于我们':'About Us'}}
						</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item>
						<span style="color: #0B0F0F;">{{detailItem.name}}</span>
					</el-breadcrumb-item>
				</el-breadcrumb>

				<div class="title margin-top50">
					{{detailItem.name}}
				</div>
				<div class="margin-top40 row-me row-center">
					<div class="title1">{{detailItem.transfer_time}}</div>
					<img src="../assets/img/icon_liulan.png" class="img-home margin-left150">
					<div class="title1 margin-left10">
						{{language==='0'?'浏览量':'Views'}}：{{detailItem.views}}
					</div>
				</div>
				<div class="content margin-top80" v-html="detailItem.introduce"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				id: '',
				detailItem: null,
				language: '', //0中文，1英文
			}
		},
		watch: {

		},
		computed: {

		},
		created() {
			this.language = localStorage.getItem("language")
			this.$bus.$emit('showBg', '1')
			this.id = this.$route.query.id
			this.getData()
		},
		mounted() {

		},
		methods: {
			//获取数据
			getData() {
				this.$postRequest('/web/relationInfo', {
					id: this.id
				}).then((res) => {
					if (res) {
						this.detailItem = res.data
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.partnerDetail {
		.main-content {
			padding: 150px 278px 100px 278px;

			.img-home {
				width: 20px;
				height: 20px;
				object-fit: scale-down;
				margin-bottom: -2px;
			}

			.title {
				font-size: 48px;
				color: #0B0F0F;
				line-height: 80px;
			}

			.title1 {
				font-size: 22px;
				color: #999999;
			}

			.content {
				font-size: 22px;
				color: #0B0F0F;
				line-height: 40px;

				/deep/ img {
					max-width: 100%;
				}
			}

			.line {
				width: 1px;
				height: 40px;
				background-color: #DDDDDD;
				margin: 0 20px;
			}

			.img-left {
				width: 20px;
				height: 30px;
				object-fit: scale-down;
				padding: 10px;
			}
		}
	}
</style>
